.listen, .approve, .reject {
    border-radius: 4px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 30px;
    padding: 5px;
    width: 80px; /* Fixed width */
    transition: transform 0.3s ease-in-out; /* Smooth scaling effect */
}

.listen {
    background-color: #3f874d;
    border: none;
}

.approve {
    background-color: #939dd9;
    border: none;
}

.reject {
    background-color: #c33737;
    border: none;
}

.approve:hover, .reject:hover, .listen:hover {
    transform: scale(1.1); /* Scale up on hover */
}

.disabled-btn {
    background-color: #d3d3d3 !important; /* Lighter color */
    color: #999 !important; /* Muted text color */
    cursor: not-allowed !important; /* Disabled cursor */
    transform: none !important; /* No scaling on hover */
}

.disabled-btn:hover {
    transform: none; /* No hover effect */
}
