/* Custom slider switch */
.slider_switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.slider_switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider_switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
}

.slider_switch .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

.slider_switch input:checked + .slider {
    background-color: #4caf50;
}

.slider_switch input:checked + .slider:before {
    transform: translateX(14px);
}
.cardy {
    width: 22%;
    display: flex;
    align-items: center;
}
.cardx {
    height: 30vh;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 8px;
    position: relative; /* Ensure ::after works */
    border-radius: 8px; /* Optional: for rounded edges */
    background: #fff; /* Background for better shadow visibility */
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.2); /* Shadow only to the right and bottom */
    transition: box-shadow 0.3s ease-in-out; /* Smooth hover effect */
}

.cardx:hover {
    box-shadow: 6px 8px 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}

.cardx::after {
    border-radius: 0 0 300px 300px;
    box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.2); /* Shadow to the right and bottom */
    content: '';
    display: block;
    height: 350px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    transition: box-shadow 0.3s ease-in-out; /* Smooth transition */
}

.cardx:hover::after {
    box-shadow: 6px 8px 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
}


.btncardy{
    background-color: #4D869C;
    border: none;
    margin-bottom: 20px;
    color: white;
}
.btncardy:hover{
    /* background-color: #396374; */
}
