.services-box{
    &.card{
        background: $white;
        &:hover{
            background: $primary;
            color: $white;
            transition: all 400ms ease;
            h4, h5, a{
                color: $white;
            }
        }
    }
}

#app-slider{
    position: relative;
    &.slider-circle-btn{
        .swiper-button{
            width: 30px;
            height: 30px;
            position: absolute;
            &.swiper-button-next {
                right: 0.2rem;
                left:auto;
                top: 45%;
                background: var(--#{$variable-prefix}primary);
                border-radius: 5rem;
                &:after {
                    content: 'next';
                    font-size:  0.75rem;;
                    color: $white;
                }
            }
            &.swiper-button-prev {
                &:after {
                    content: 'prev';
                    font-size:  0.75rem;;
                    color: $white;
                }
                right:auto;
                left: 0.2rem;
                top: 45%;
                background: var(--#{$variable-prefix}primary);
                border-radius: 5rem;
            }
        }
    }
}
#testimonial-slider{
    position: relative;
    &.slider-circle-btn{
        .swiper-button{
            width: 30px;
            height: 30px;
            position: absolute;
            &.swiper-button-next {
                right: 0.2rem;
                left:auto;
                top: 45%;
                background: var(--#{$variable-prefix}primary);
                border-radius: 5rem;
                &:after {
                    content: 'next';
                    font-size:  0.75rem;;
                    color: $white;
                }
            }
            &.swiper-button-prev {
                &:after {
                    content: 'prev';
                    font-size:  0.75rem;;
                    color: $white;
                }
                right:auto;
                left: 0.2rem;
                top: 45%;
                background: var(--#{$variable-prefix}primary);
                border-radius: 5rem;
            }
        }
    }
}
#testimonial-slider-two{
    position: relative;
    &.slider-circle-btn{
        .swiper-button{
            width: 30px;
            height: 30px;
            position: absolute;
            &.swiper-button-next {
                right: 0.2rem;
                left:auto;
                top: 45%;
                background: var(--#{$variable-prefix}primary);
                border-radius: 5rem;
                &:after {
                    content: 'next';
                    font-size:  0.75rem;;
                    color: $white;
                }
            }
            &.swiper-button-prev {
                &:after {
                    content: 'prev';
                    font-size:  0.75rem;;
                    color: $white;
                }
                right:auto;
                left: 0.2rem;
                top: 45%;
                background: var(--#{$variable-prefix}primary);
                border-radius: 5rem;
            }
        }
    }
}
.team-image{
    &:hover{
        background: $primary;
        color: $white;
        transition: all 400ms ease;
        h6{
            color: $white;
        }
    }
}
.services {
    height: 80px;
    width: 80px;
    line-height: 80px;
}