.header-one{
    &.offcanvas-lg{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

}
.landing-header{
    ul.navbar-nav{
        >li {
            >.nav-link {
                font-weight: 500;
                font-size: 1rem;
                color: $body-color;
                padding-left: 1.5em;
                &.active{
                    color: $primary;
                }
            }
        }
    }
}
ul.nav li.dropdown:hover ul.dropdown-menu{ display: block; }
.landing-header{
    .navbar{
        &.hover-nav{
            ul.navbar-nav{
                .nav-item{
                    &:hover{
                        .nav-link {
                            color:  $body-color;
                            &.active{
                                color: $primary;
                            }
                        }
                    } 
                }
            }
        } 
    } 
}

@include media-breakpoint-down(xl) {
    .landing-header{
        ul.navbar-nav{
            >li {
                >.nav-link {
                    padding: 0.5em 1em 1em;
                }
            }
        }
    }
    ul.iq-header-sub-menu {
        padding-left: 1rem;
    }
   
}